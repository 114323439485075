import React from 'react'

interface TermsCheckProps {
  setTermCheck: (value: boolean) => void
}

const TermsCheck = ({ setTermCheck }: TermsCheckProps ) => {
  return (
    <div className="flex mt-10 px-1 items-start">
      <input type="checkbox" className="mr-4 mt-1" onChange={(e) => {
        setTermCheck(e.target.checked)
      }}/>
      <p className="text-sm text-gray-500">Ja, jeg godtar at Skjold.ai behandler data i samsvar med våre 
        <a href="https://www.skjold.ai/personvern" target='_blank' className="text-primary font-medium ml-1 underline">brukervilkår</a>
      . Dataen lagres og benyttes for å forhindre nettsvindel.</p>
    </div>
  )
}

export default TermsCheck;