import React from 'react';
import { BsArrowRight } from "react-icons/bs";

interface CheckMessageButtonProps {
	setShowTextChecker: (value: boolean) => void;
	setShowAnalyzeLoading: (value: boolean) => void;
	setShowResultPage: (value: boolean) => void;
	handleCheck: () => void;
	termCheck: boolean;
	selectedSourceType: string;
	copiedText: string;
	setShowImageChecker: (value: boolean) => void;
}

const CheckMessageButton = ({ setShowImageChecker, copiedText, setShowTextChecker, setShowAnalyzeLoading, handleCheck, termCheck, selectedSourceType}: CheckMessageButtonProps) => {

	const handleClick = () => {
		setShowTextChecker(false);
		setShowImageChecker(false);
		setShowAnalyzeLoading(true);
		handleCheck();
	}

	return (
		<button
            disabled={!termCheck || !selectedSourceType || !copiedText}
            onClick={handleClick}
            className={`bg-primary px-5 py-4 rounded-xl flex flex-row items-center justify-center mt-10 ${(!copiedText || !termCheck || !selectedSourceType) && 'opacity-50 cursor-not-allowed'}`}
            style={{ pointerEvents: (!termCheck || !selectedSourceType || !copiedText) ? 'none' : 'auto' }}
        >
            <h1 className="text-sm font-semibold text-white">Sjekk melding</h1>
            <BsArrowRight size={'1.2em'} className="text-white ml-2" />
        </button>
	);
};

export default CheckMessageButton;
