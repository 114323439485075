import React, { useState } from 'react'
import {db} from '../../firebase';
import { addDoc, collection, setDoc } from 'firebase/firestore';
interface FeedbackBoxProps {
    selectedSourceType: string
    showThankYouPage: () => void
}

const FeedbackBox = ({ selectedSourceType, showThankYouPage }: FeedbackBoxProps) => {
    const [feedback, setFeedback] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSubmitFeedback = async () => {
        setIsLoading(true);
        try {
            // use firebase v9 
            const collectionRef = await collection(db, 'feedback');
            const docRef = await addDoc(collectionRef, {
                id: '',
                option: selectedSourceType,
                value: feedback
            });
            await setDoc(docRef, { id: docRef.id }, { merge: true });
            if(docRef.id) {
                console.log('Feedback submitted with ID:', docRef.id);
                showThankYouPage();
            }
        } catch (error) {
            console.error('Error submitting feedback:', error);
        }
        setIsLoading(false);
    };

    if(!selectedSourceType) return null
  return (
        <div className='w-full flex flex-col mt-10 px-4'>
             <textarea
                className="w-full h-28 sm:h-28 p-2 border border-stone-300 rounded-2xl placeholder:text-md"
                placeholder="Hva synes du var nyttig?"
                onChange={(e) => setFeedback(e.target.value)}
                value={feedback}
            ></textarea>
            <button disabled={!feedback || isLoading} onClick={handleSubmitFeedback} className={`border-stone-300 bg-primary text-white py-4 px-4 rounded-2xl mt-4 font-bold ${(!feedback || isLoading) ? 'opacity-50 cursor-not-allowed' : ''}`}>Send Feedback</button>
        </div>
  )
}

export default FeedbackBox;