import React from 'react'
import Questions from '../Questions'
import PlussMobil from '../PlussMobil';

const ThankYouPage = ({
  moveToStartPage,
  partnerName
}: {
  moveToStartPage: () => void;
  partnerName: string;
}) => {
  return (
    <div className='w-full md:w-2/3 lg:w-1/3 mx-auto flex flex-col px-4 md:px-8 lg:px-12 py-5'>
      <div className='font-medium mt-20 text-center text-5xl text-primary'>
        Takk for tilbakemeldingen!
      </div>
      <Questions moveToStartPage={moveToStartPage} />
      <PlussMobil partnerName={partnerName} />
    </div>
  )
}

export default ThankYouPage