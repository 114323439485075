import React, { useEffect, useState } from 'react';

const PlussMobil = ({
	partnerName
}: {
	partnerName: string
}) => {
	const [name, setName] = useState<string>('');

	useEffect(() => {
		if(partnerName === 'plussmobil') {
			setName('Plussmobil');
		} else if(partnerName === 'tekniskmultimedia') {
			setName('Teknisk Multimedia');
		} else if(partnerName === 'demo') {
			setName('demo');
		}
		else if(partnerName === 'powerit') {
			setName('powerit');
		}
		else {
			setName(partnerName);
		}
	}, [partnerName])

	const handleClick = () => {
		if(partnerName === 'plussmobil') {
			window.open('https://www.plussmobil.no/skjold', '_blank');
		} else if(partnerName === 'tekniskmultimedia') {
			window.open('https://tekniskmultimedia.no', '_blank');
		} else if(partnerName === 'powerit') {
			window.open('https://powerit.no/', '_blank');
		} else if(partnerName === 'demo') {
			window.open('https://skjold.ai/', '_blank');
		} else if(partnerName === 'tenerity') {
			window.open('https://www.tenerity.com', '_blank');
		}
	}

	if(partnerName === '') {
		return null;
	}
	return (
		<div className="flex flex-col mt-10 bg-secondary p-5 py-3 rounded-lg">
			<div className="flex flex-row">
				<p className="text-sm font-bold mr-5">I samarbeid med</p>
				{partnerName === 'plussmobil' && <img
					width={'20'}
					height={'5'}
					src="/images/plussmobil.png"
					alt="plussmobil"
				/> && <p className="text-sm font-bold ml-2">{name}</p>}
				{partnerName === 'tekniskmultimedia' && <img
					width={'130'}
					height={'5'}
					src="/images/tekniskmultimedia.png"
					alt="tekniskmultimedia"
				/>}
				{partnerName === 'powerit' && <img
					width={'130'}
					height={'5'}
					src="/images/pit.png"
					alt="powerit"
				/>} 

				{partnerName === 'tenerity' && <img
					width={'130'}
					height={'5'}
					src="/images/tenerity.png"
					alt="powerit"
				/>} 

				
					
			</div>
			<div className="flex flex-row items-center justify-between mt-3 rounded-md ">
				<p className="text-sm w-2/3">
					Skjold.ai hjelper kunder av {name} med digital trygghet.
				</p>
				<button className="font-bold px-4 py-2 rounded-lg border-x border-y border-stone-300" onClick={handleClick}>
					Les mer
				</button>
			</div>
		</div>
	);
};

export default PlussMobil;
