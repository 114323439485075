import React, { useState } from 'react'

interface CheckTypeProps {
  heading: string;
  options: string[];
  onSelectOption: (option: string) => void;
}

const CheckType = ({ heading, options, onSelectOption }: CheckTypeProps) => {
  const [selectedOption, setSelectedOption] = useState<string>('');

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    onSelectOption(option);
  };

  return (
    // small heading - Hvor er teksten/meldingen fra? and 3 buttons - E-post, SMS, Annet 
    <div className="w-full mt-10 px-4">
      <h1 className="text-lg font-medium mb-4 text-center">{heading}</h1>

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
        {options.map((option, index) => (
          <div
            key={index}
            className={`p-2 py-2 bg-white border rounded-md flex items-center justify-center cursor-pointer ${
              selectedOption === option ? 'border-blue-950' : ''
            }`}
            onClick={() => handleOptionClick(option)}
          >
            <h1 className={`text-base font-${selectedOption === option ? 'bold' : 'medium'}`}>{option}</h1>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CheckType