import React, { useEffect, useState } from 'react';
import CheckType from '../CheckType';
import TermsCheck from '../TermsCheck';
import CheckMessageButton from '../CheckMessageButton';
import axios from 'axios';
import qs from 'qs';
import { IoArrowBack } from "react-icons/io5";
import { ToastContainer, toast } from 'react-toastify';
import { createWorker } from 'tesseract.js';

interface ImageCheckerProps {
	setShowImageChecker: (value: boolean) => void;
	setShowAnalyzeLoading: (value: boolean) => void;
	setShowResultPage: (value: boolean) => void;
	setResultType: (value: string) => void;
	setShowStart: (value: boolean) => void;
    setResultText: (value: string) => void;
    partnerName?: string;
}

const ImageChecker = ({ partnerName, setShowImageChecker, setShowAnalyzeLoading, setShowResultPage, setResultType, setShowStart, setResultText }: ImageCheckerProps) => {
	const [copiedText, setCopiedText] = useState<string>('');
	const [selectedSourceType, setSelectedSourceType] = useState<string>('');
    const [termCheck, setTermCheck] = useState<boolean>(false);
    const [uploadedPhoto, setUploadedPhoto] = useState<string | null>(null); // State to manage uploaded photo

    useEffect(() => {
        extractTextFromImage();
    }, [uploadedPhoto])

    const notify = () => toast('Error', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

	const handleCheck = async () => {
		try {
			let requestData;

            if(partnerName !== undefined && partnerName !== '') {
                requestData = {
                    q: copiedText,
                    s: selectedSourceType,
                    responseType: 'TEXT',
                    phone: partnerName
                };
            } else {
                requestData = {
                    q: copiedText,
                    s: selectedSourceType,
                    responseType: 'TEXT'
                };
            }
	
			const response = await axios.post('https://skjoldwhitelisting.azurewebsites.net/GetAIResponse', qs.stringify(requestData), {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			});

			const responseText = response.data;

            // Extract result type
            let resultType = '';
            if (responseText.includes('Grønn')) {
                resultType = 'safe';
            } else if (responseText.includes('Gul')) {
                resultType = 'warn';
            } else if (responseText.includes('Rød')) {
                resultType = 'risk';
            }

            // Extract result text
            let resultText = responseText;
            const delimiterIndex = responseText.indexOf(' - ');
            if (delimiterIndex !== -1) {
                resultText = responseText.substring(delimiterIndex + 3); // +3 to skip the delimiter and space
            }

            // Set the result type and text
            setResultType(resultType);
            setResultText(resultText);
            setShowImageChecker(false);
            setShowAnalyzeLoading(false);
            setShowResultPage(true);
		} catch (error) {
			console.error('Error:', error);
			// Handle error
			setShowAnalyzeLoading(false);
			setShowImageChecker(true);
            setResultText('');
            setResultType('');
            setTermCheck(false);
            notify();
		}
	}

    const handleUploadPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target) {
                    setUploadedPhoto(e.target.result as string);
                }
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const handleRemovePhoto = () => {
        setUploadedPhoto(null);
        setCopiedText('');
    };

	const handleBack = () => {
		setShowImageChecker(false);
		setShowAnalyzeLoading(false);
		setShowResultPage(false);
		setShowStart(true);
	}

    const extractTextFromImage = async () => {
        // use tessaract to extract text from image
        // set the extracted text to copiedText

        if(!uploadedPhoto) {
            return;
        }

        const worker = createWorker({
            // logger: m => console.log(m),
        });

        await (await worker).loadLanguage('eng');
        await (await worker).initialize('eng');
        const { data: { text } } = await (await worker).recognize(uploadedPhoto);
        console.log(text);
        setCopiedText(text);
        await (await worker).terminate();
    }

	return (
		<div className="container mx-auto px-4 py-5 sm:px-8 md:px-12 mt-5" style={{ maxWidth: '600px' }}>
            <ToastContainer />
            {/* Back icon */}
            <div onClick={handleBack} className="w-full flex flex-row items-center justify-start cursor-pointer px-4 mb-5">
                <IoArrowBack />
                <p className="text-lg font-medium ml-2">Tilbake</p>
            </div>

            <div className="pl-4 sm:pl-8">
                <h1 className="text-2xl sm:text-3xl font-medium">
                    Last opp skjermbilde
                </h1>
            </div>

             <div className="w-full flex flex-col px-4 sm:px-6">
                {/* Suspicious text section */}
                <div className="w-full py-6 sm:py-10">
                    <div className="flex flex-col">
                        <h3 className="text-lg sm:text-lg font-semibold">Skjermbilde</h3>
                        <p className="text-sm sm:text-base mt-2">
                            Ta skjermbilde på forhånd og del det med Skjold.ai ved å klikke på knappen under
                        </p>
                    </div>
                </div>
                {/* Textarea for pasting text */}
                <div className="w-full flex flex-col items-center px-4 py-12 sm:px-6 border-dashed border-2 border-gray-300 rounded-md p-3">
                    {/* Button to upload a photo */}
                    {!uploadedPhoto && (
                        <label htmlFor="upload-photo" className="relative w-2/3 cursor-pointer border border-gray-300 rounded-3xl p-3 flex items-center justify-center">
                            {/* upload icon */}
                            <img width={'20'} height={'20'} src="/images/upload-icon.png" alt="upload" />
                            <span className="text-base font-medium ml-4">Last opp skjermbilde</span>
                            {/* Input element to select a photo */}
                            <input id="upload-photo" type="file" className="sr-only" accept="image/*" onChange={handleUploadPhoto} />
                        </label>
                    )}
                    {/* Uploaded photo */}
                    {uploadedPhoto && (
                        <div className="relative p-2 flex items-center justify-center">
                            <img src={uploadedPhoto} alt="uploaded" className="max-w-40 h-auto" />
                            <button className="absolute -mt-2 -mr-2 w-5 h-5 top-0 right-0 bg-primary text-white rounded-lg flex items-center justify-center pb-1" onClick={handleRemovePhoto}>
                                x
                            </button>
                        </div>
                    )}
                </div>
            </div>

            {/* CheckType component */}
            <CheckType
                heading={'Hvor er teksten/meldingen fra? '}
                options={['E-post', 'SMS', 'Annet', 'Nettside', 'Sosiale Medier']}
                onSelectOption={(option) => setSelectedSourceType(option)}
            />

            {/* Additional content */}
            <div className="w-full flex flex-col px-4 sm:px-8">
                <TermsCheck setTermCheck={setTermCheck}/>
                <CheckMessageButton
                    setShowImageChecker={setShowImageChecker}
                    setShowTextChecker={setShowImageChecker}
                    setShowAnalyzeLoading={setShowAnalyzeLoading}
                    setShowResultPage={setShowResultPage}
                    handleCheck={handleCheck}
                    termCheck={termCheck}
                    selectedSourceType={selectedSourceType}
                    copiedText={copiedText}
                />
                <div className="w-full flex flex-row mt-5 items-center justify-center">
                    <p className="text-sm font-normal">
                        Spørsmål? -
                        <a href="#" className="text-primary font-medium ml-1 underline">
                            Se våre hjelpesider
                        </a>
                    </p>
                </div>
            </div>
        </div>
	);
};

export default ImageChecker;
