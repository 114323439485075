import React from 'react';
import { GoChevronDown } from 'react-icons/go';
import { TbMenu2 } from "react-icons/tb";


interface HeaderProps {
	moveToStartPage: () => void;
}

const Header = ({
	moveToStartPage
}: HeaderProps) => {
	// const [showDropdown, setShowDropdown] = React.useState(false);
	const [selectedLanguage, setSelectedLanguage] = React.useState('BV');
	const [showMenu, setShowMenu] = React.useState(false); 
	
	// const handleDropdown = () => {
	// 	setShowDropdown(!showDropdown);
	// };

	const handleSelectedLanguage = (language: string) => {
		setSelectedLanguage(language);
		// setShowDropdown(false);
	};

	const languages = [
		{ name: 'US', flag: '/images/US.png' },
		{ name: 'BV', flag: '/images/BV.png' },
	];

	const renderLanguages = languages.map((language, index) => {
		return (
			<li
				key={index}
				className="hover:bg-gray-100 px-3 py-2"
				onClick={() => handleSelectedLanguage(language.name)}
			>
				<img width={'30'} height={'30'} src={language.flag} alt="flag" />
			</li>
		);
	});

	const handleClick = () => {
		moveToStartPage();
	};

	const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

	return (
        <header className="border-x border-y">
            <div className="container mx-auto px-6 py-5">
                <div onClick={handleClick} className="flex items-center justify-between cursor-pointer">
                    {/* <a href="#" className="mx-3 font-semibold text-lg"> */}
                        <div className="flex items-center w-0.5">
                            <div
                                
                                className="mr-4 flex flex-row items-center cursor-pointer"
                            >
                                <img
                                    width={'30'}
                                    height={'30'}
                                    src="/images/Vector.png"
                                    alt="Skjold"
                                    className="cursor-pointer"
                                />
                                    <p className="mx-3 font-semibold text-lg">
                                        Skjold
                                    </p>
                            </div>
                        </div>
                    {/* </a> */}
                    <div className="w-full hidden justify-between md:flex md:items-center md:justify-end">
                        <a href="https://www.skjold.ai/om-oss-1" target='_blank' className="mx-10">
                            Om Skjold.ai
                        </a>
                        <a href="https://hjelp.skjold.ai/" target='_blank' className="mx-10">
                            Hjelp
                        </a>
                        <div className="relative mx-10">
                            <div
                                // onClick={handleDropdown}
                                className="flex items-center cursor-pointer"
                            >
                                <img
                                    width={'25'}
                                    height={'25'}
                                    src={`/images/${selectedLanguage}.png`}
                                    alt="flag"
                                    style={{
                                        marginRight: '7px',
                                    }}
                                />
                                <GoChevronDown size={'1.3em'} />
                            </div>
                            {/* <div className="absolute top-0 mt-10 w-40 bg-white">
                                <ul>{showDropdown && renderLanguages}</ul>
                            </div> */}
                        </div>
                    </div>
                    {/* Render hamburger icon */}
                    <div className="md:hidden cursor-pointer" onClick={toggleMenu}>
                        <TbMenu2 size={'1.5em'} />
                    </div>
                </div>
                {/* Conditional rendering of mobile menu */}
                {showMenu && (
                    <div className="fixed top-0 left-0 w-full h-full bg-color opacity-100 z-10">
                        <div className="container mx-auto px-6 py-5 flex flex-col justify-between h-full">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center w-0.5 cursor-pointer">
                                    <div
                                        onClick={handleClick}
                                        className="mr-4 flex flex-row items-center cursor-pointer"
                                    >
                                        <img
                                            width={'30'}
                                            height={'30'}
                                            src="/images/Vector.png"
                                            alt="Skjold"
                                            className="cursor-pointer"
                                        />
                                        <a href="#" className="mx-3 font-semibold text-lg cursor-pointer">
                                            Skjold
                                        </a>
                                    </div>
                                </div>
								<div className="md:hidden cursor-pointer" onClick={toggleMenu}>
									<TbMenu2 size={'1.5em'} />
								</div>
							</div>
							<div className="flex flex-col justify-between h-1/2">
								<a href="https://www.skjold.ai/om-oss-1" target='_blank' className="mx-1 text-3xl font-medium">
									Om Skjold.ai
								</a>
								<a href="https://hjelp.skjold.ai/" target='_blank' className="mx-1 text-3xl font-medium">
									Hjelp
								</a>
								<a href="#" className="mx-1 text-3xl font-medium">
									Sjekk skjermbilde
								</a>
								<a href="#" className="mx-1 text-3xl font-medium">
									Sjekk tekst
								</a>
							</div>
							<div className="relative mx-1">
								<div
									// onClick={handleDropdown}
									className="flex items-center cursor-pointer"
								>
									<img
										width={'25'}
										height={'25'}
										src={`/images/${selectedLanguage}.png`}
										alt="flag"
										style={{
											marginRight: '7px',
										}}
									/>
									<GoChevronDown size={'1.3em'} />
								</div>
								{/* <div className="absolute top-0 mt-10 w-40 bg-white">
									<ul>{showDropdown && renderLanguages}</ul>
								</div> */}
							</div>
                        </div>
                    </div>
                )}
            </div>
        </header>
	);
};

export default Header;
