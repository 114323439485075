import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import Start from './components/Start';
import TextChecker from './components/TextChecker';
import AnalyzeLoading from './components/AnalyzeLoading';
import ResultPage from './components/ResultPage';
import ImageCheker from './components/ImageChecker';
import ThankYouPage from './components/ThankYouPage';



export default function App() {

	// TODO: Will be replaced with context API or Redux when the project grows

	// get the partner=plussmobil query parameter
	const urlParams = new URLSearchParams(window.location.search);
	const partner = urlParams.get('partner');
	const [showStart, setShowStart] = useState(true);
	const [showTextChecker, setShowTextChecker] = useState(false);
	const [showAnalyzeLoading, setShowAnalyzeLoading] = useState(false);
	const [showResultPage, setShowResultPage] = useState(false);
	const [showThankYouPage, setShowThankYouPage] = useState(false);

	const [resultType, setResultType] = useState<string>('');
	const [resultText, setResultText] = useState<string>('');
	const [showImageChecker, setShowImageChecker] = useState(false);
	const [partnerName, setPartnerName] = useState<string>('');

	useEffect(() => {
		if (partner === 'plussmobil') {
			setPartnerName('plussmobil');
		} else if(partner === 'tekniskmultimedia') {
			setPartnerName('tekniskmultimedia');
		} else if(partner === 'powerit') {
			setPartnerName('powerit');
		} else if(partner === 'demo') {
			setPartnerName('demo');
		} else if(partner === 'tenerity') {
			setPartnerName('tenerity');
		}
		
		else {
			setPartnerName('');
		}
	}, [partner])

	const moveToStartPage = () => {
		setShowTextChecker(false);
		setShowAnalyzeLoading(false);
		setShowResultPage(false);
		setShowImageChecker(false);
		setShowThankYouPage(false);
		setShowStart(true);
	}

	const handleShowThankYouPage = () => {
		setShowResultPage(false);
		setShowThankYouPage(true);
	}

	return (
		<div className='w-full h-screen'>
			<Header
				moveToStartPage={moveToStartPage}
			/>
			<main className='w-full flex flex-col items-center'>
				{showStart && <Start partnerName={partnerName} setShowImageChecker={setShowImageChecker} setShowStart={setShowStart} setShowTextChecker={setShowTextChecker} />}
				{showTextChecker && <TextChecker partnerName={partnerName} setShowImageChecker={setShowImageChecker} moveToStartPage={moveToStartPage} setResultText={setResultText} setResultType={setResultType} setShowResultPage={setShowResultPage} setShowTextChecker={setShowTextChecker} setShowAnalyzeLoading={setShowAnalyzeLoading} />}
				{showImageChecker && <ImageCheker partnerName={partnerName} setResultText={setResultText} setShowStart={setShowStart} setResultType={setResultType} setShowResultPage={setShowResultPage} setShowImageChecker={setShowImageChecker} setShowAnalyzeLoading={setShowAnalyzeLoading} />}
				{showAnalyzeLoading && <AnalyzeLoading moveToStartPage={moveToStartPage}/>}
				{showResultPage && <ResultPage partnerName={partnerName} showThankYouPage={handleShowThankYouPage} moveToStartPage={moveToStartPage} resultText={resultText} resultType={resultType}/>}
				{showThankYouPage && <ThankYouPage partnerName={partnerName} moveToStartPage={moveToStartPage}/>}
			</main>
		</div>
	);
}