// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBzmpC8M8dutEl2xfPXgi-lDzccBrCN4B4",
  authDomain: "skjold-d1a03.firebaseapp.com",
  projectId: "skjold-d1a03",
  storageBucket: "skjold-d1a03.appspot.com",
  messagingSenderId: "489226509506",
  appId: "1:489226509506:web:a3f5924833c7503bc4ef5b",
  measurementId: "G-5NFWVDGENP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// firestore is the database
export const db = getFirestore(app);
// const analytics = getAnalytics(app);