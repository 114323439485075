import React, { useState } from 'react'
import CheckType from '../CheckType'
import Questions from '../Questions'
import ResultBox from '../ResultBox'
import FeedbackBox from '../FeedbackBox.tsx'
import PlussMobil from '../PlussMobil'

interface ResultPageProps {
    resultType: string
    resultText: string
    showThankYouPage: () => void
    moveToStartPage: () => void
    partnerName: string
}

const ResultPage = ({showThankYouPage, resultType, resultText, moveToStartPage, partnerName}: ResultPageProps) => {
  const [selectedSourceType, setSelectedSourceType] = useState<string>('');

  return (
    <div className='w-full md:w-2/3 lg:w-1/3 mx-auto flex flex-col px-4 md:px-8 lg:px-12 py-5'>
      <ResultBox
          type={resultType}
          message={resultText}
      />
      <CheckType
          heading={'Var dette nyttig?'}
          options={['Ja', 'Nei', 'Vet ikke']}
          onSelectOption={(option) => setSelectedSourceType(option)}
      />
      <FeedbackBox showThankYouPage={showThankYouPage} selectedSourceType={selectedSourceType} />
      <Questions moveToStartPage={moveToStartPage} />
      <PlussMobil partnerName={partnerName} />
  </div>

  )
}

export default ResultPage