import React from 'react'
import PlussMobil from '../PlussMobil';

interface StartProps {
  setShowTextChecker: (value: boolean) => void;
  setShowStart: (value: boolean) => void;
  setShowImageChecker: (value: boolean) => void;
  partnerName: string;
}

const Start = ({ setShowImageChecker, setShowTextChecker, setShowStart, partnerName }: StartProps) => {
  const handleClick = (type: string) => {
    console.log(type)
    if(type === 'image') {
      setShowImageChecker(true);
    } else {
      setShowTextChecker(true);
    }
    setShowStart(false);
  }

  return (
    <div className="container mx-auto px-4 py-5 w-full sm:px-8 md:px-12 mt-5" style={{ maxWidth: '600px' }}>
      <div className="text-center">
          <h1 className="text-5xl font-medium mb-10">Har du mottatt en mistenkelig melding? </h1>
          <p className="text-sm">Sjekk og rapporter for å avsløre nettsvindel og digital kriminalitet</p>
      </div>

      <div className="flex flex-row mt-10">
        <div onClick={() => handleClick('image')} className="w-1/2 bg-white p-5 py-10 mr-5 cursor-pointer rounded-md border-x border-y border-stone-300"> 
          <div className="text-center flex flex-col items-center justify-center">
            <img width={'40'} height={'40'} src="/images/camera.png" alt="camera" />
            <h1 className="text-sm font-medium mt-5">Del et skjermbilde</h1>
          </div>
        </div>
        <div onClick={() => handleClick('text')} className="w-1/2 bg-white p-5 py-10 cursor-pointer rounded-md border-x border-y border-stone-300">
          <div className="text-center flex flex-col items-center justify-center">
            <img width={'40'} height={'40'} src="/images/clipboard.png" alt="paste" />
            <h1 className="text-sm font-medium mt-5">Lim inn tekst</h1>
          </div>
        </div>
      </div>

      {/* Pluss Mobil div */}
      <PlussMobil partnerName={partnerName} />
    </div> 
  )
}

export default Start;