import React, { useState } from 'react'

interface ResultBoxProps {
    type: string
    message: string
}

enum Headings {
    risk = 'Stopp - Fremstår utrygt!',
    warn = 'Vær forsiktig',
    safe = 'Fremstår som trygg'
}

const ResultBox = ({ type, message }: ResultBoxProps) => {
    const [showFullMessage, setShowFullMessage] = useState<boolean>(false);

    const getHeading = (type: string) => {
        switch (type) {
            case 'risk':
                return Headings.risk
            case 'warn':
                return Headings.warn
            case 'safe':
                return Headings.safe
            default:
                return Headings.safe
        }
    }

    const getBgClasses = (type: string) => {
        switch (type) {
            case 'risk':
                return 'risk-dark-bg risk-light-bg';
            case 'warn':
                return 'warn-dark-bg warn-light-bg';
            case 'safe':
                return 'safe-dark-bg safe-light-bg';
            default:
                return 'safe-dark-bg safe-light-bg';
        }
    };

    const getIcon = (type: string) => {
        switch (type) {
            case 'risk':
                return '/images/risk.png'
            case 'warn':
                return '/images/warn.png'
            case 'safe':
                return '/images/safe.png'
            default:
                return '/images/safe.png'
        }
    }

    const getTextColorClass = (type: string) => {
        switch (type) {
            case 'risk':
                return 'text-dark-red';
            case 'safe':
                return 'text-dark-green';
            case 'warn':
                return 'text-dark-yellow';
            default:
                return 'text-dark-green'; // Default color if type is not recognized
        }
    };

  const bgClasses = getBgClasses(type);
  return (
    <div className='w-full flex flex-col mt-10 px-4'>
        <div className={`w-full p-4 md:p-6 lg:p-8 h-auto ${bgClasses.split(' ')[0]} rounded-t-lg flex items-center justify-center`}>
            <img
                width={'20'}
                height={'20'}
                src={getIcon(type)}
                alt="icon"
                style={{ marginRight: '10px' }}
            />
            <h1 className="text-lg font-medium text-white">{getHeading(type)}</h1>
        </div>
        <div className={`w-full p-4 md:p-6 lg:p-8 h-auto ${bgClasses.split(' ')[1]} rounded-b-lg flex items-center justify-center`}>
            <p className={`text-md font-medium ${getTextColorClass(type)}`}>
                {showFullMessage ? message ? message : 'Message not got' : message?.slice(0, 300)}
                {message.length > 300 && (
                    <span
                        className="text-primary cursor-pointer"
                        onClick={() => setShowFullMessage(!showFullMessage)}
                    >
                        {showFullMessage ? ' Les mindre' : ' Les mer'}
                    </span>
                )}
            </p>
        </div>
    </div>
  )
}

export default ResultBox;