import React from 'react'

interface QuestionsProps { 
  moveToStartPage: () => void
}

const Questions = ({ moveToStartPage }: QuestionsProps) => {
  return (
    <div className="w-full flex flex-col px-4 mt-10 justify-center">
      <h1 className="text-lg font-semibold text-primary">Spørsmål?</h1>
      <p className="text-md font-normal mt-2">Har du spørsmål om analysen eller svaret du fikk? Svar på denne meldingen eller kontakt{' '}
        <a href="mailto:hei@skjold.ai" className="text-primary font-medium underline">
          hei@skjold.ai
        </a>
      </p>
      <button onClick={moveToStartPage} className="w-full text-sm font-medium text-primary mt-10 border border-primary rounded-xl px-5 py-3">
        Sjekk ny melding
      </button>
    </div>
  )
}

export default Questions;