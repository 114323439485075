import React, { useState } from 'react';
import CheckType from '../CheckType';
import TermsCheck from '../TermsCheck';
import CheckMessageButton from '../CheckMessageButton';
import axios from 'axios';
import qs from 'qs';
import { IoArrowBack } from "react-icons/io5";
import { ToastContainer, toast } from 'react-toastify';

interface TextCheckerProps {
	setShowTextChecker: (value: boolean) => void;
	setShowAnalyzeLoading: (value: boolean) => void;
	setShowResultPage: (value: boolean) => void;
	setResultType: (value: string) => void;
    setResultText: (value: string) => void;
    moveToStartPage: () => void;
    setShowImageChecker: (value: boolean) => void;
    partnerName?: string;
}

const TextChecker = ({ partnerName, setShowImageChecker, moveToStartPage, setShowTextChecker, setShowAnalyzeLoading, setShowResultPage, setResultType, setResultText }: TextCheckerProps) => {
	const [copiedText, setCopiedText] = useState<string>('');
	const [selectedSourceType, setSelectedSourceType] = useState<string>('');
    const [termCheck, setTermCheck] = useState<boolean>(false);

    const notify = () => toast('Error', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const handlePaste = async () => {
        try {
            const clipboardText = await navigator.clipboard.readText();
            setCopiedText(clipboardText);
        } catch (error) {
            console.error('Error reading clipboard:', error);
        }
    };

	const handleCheck = async () => {
		try {
			let requestData;

            if(partnerName !== undefined && partnerName !== '') {
                requestData = {
                    q: copiedText,
                    s: selectedSourceType,
                    responseType: 'TEXT',
                    phone: partnerName
                };
            } else {
                requestData = {
                    q: copiedText,
                    s: selectedSourceType,
                    responseType: 'TEXT'
                };
            }
	
			const response = await axios.post('https://skjoldwhitelisting.azurewebsites.net/GetAIResponse', qs.stringify(requestData), {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			});

			const responseText = response.data;

            // Extract result type
            let resultType = '';
            if (responseText.includes('Grønn')) {
                resultType = 'safe';
            } else if (responseText.includes('Gul')) {
                resultType = 'warn';
            } else if (responseText.includes('Rød')) {
                resultType = 'risk';
            }

            // Extract result text
            let resultText = responseText;
            const delimiterIndex = responseText.indexOf(' - ');
            if (delimiterIndex !== -1) {
                resultText = responseText.substring(delimiterIndex + 3); // +3 to skip the delimiter and space
            }

            // Set the result type and text
            setResultType(resultType);
            setResultText(resultText);
            setShowImageChecker(false);
            setShowTextChecker(false);
            setShowAnalyzeLoading(false);
            setShowResultPage(true);
		} catch (error) {
			console.error('Error:', error);
			// Handle error
			setShowAnalyzeLoading(false);
            setShowImageChecker(false);
			setShowTextChecker(true);
            setResultText('');
            setResultType('');
            setTermCheck(false);
            notify();
		}
	}

	const handleBack = () => {
		moveToStartPage();
	}

	return (
		<div className="container mx-auto px-4 py-5 sm:px-8 md:px-12 mt-5" style={{ maxWidth: '600px' }}>
            <ToastContainer />
            {/* Back icon */}
            <div onClick={handleBack} className="w-full flex flex-row items-center justify-start cursor-pointer px-4 mb-5">
                <IoArrowBack />
                <p className="text-lg font-medium ml-2">Tilbake</p>
            </div>

            <div className="pl-4 sm:pl-8">
                <h1 className="text-2xl sm:text-3xl font-medium">
                    Lim inn teksten du ønsker å sjekke
                </h1>
            </div>

            <div className="w-full flex flex-col mt-5 px-4 sm:px-6">
                {/* Suspicious text section */}
                <div className="w-full p-1 py-6 sm:py-10">
                    <div className="text-center flex flex-row items-center justify-between">
                        <h1 className="text-lg sm:text-xl font-semibold"></h1>
                        <button onClick={handlePaste} className="flex flex-row items-center font-bold px-4 py-2 sm:px-5 sm:py-2.5 rounded-xl border border-stone-300 bg-primary text-white">
                            <img
                                width={'18'}
                                height={'18'}
                                src="/images/white-clipboard.png"
                                alt="paste"
                                className="mr-2"
                            />
                            Lim inn
                        </button>
                    </div>
                </div>
                {/* Textarea for pasting text */}
                <textarea
                    className="w-full h-32 sm:h-48 p-2 border border-stone-300 rounded-2xl placeholder:text-md"
                    placeholder="Kopier teksten og lim den inn her"
                    onChange={(e) => setCopiedText(e.target.value)} 
                    value={copiedText}
                ></textarea>
            </div>

            {/* CheckType component */}
            <CheckType
                heading={'Hvor er teksten/meldingen fra? '}
                options={['E-post', 'SMS', 'Annet', 'Nettside', 'Sosiale Medier']}
                onSelectOption={(option) => setSelectedSourceType(option)}
            />

            {/* Additional content */}
            <div className="w-full flex flex-col px-4 sm:px-8">
                <TermsCheck setTermCheck={setTermCheck}/>
                <CheckMessageButton
                    setShowImageChecker={setShowImageChecker}
                    setShowTextChecker={setShowTextChecker}
                    setShowAnalyzeLoading={setShowAnalyzeLoading}
                    setShowResultPage={setShowResultPage}
                    handleCheck={handleCheck}
                    termCheck={termCheck}
                    selectedSourceType={selectedSourceType}
                    copiedText={copiedText}
                />
                <div className="w-full flex flex-row mt-5 items-center justify-center">
                    <p className="text-sm font-normal">
                        Spørsmål? -
                        <a href="#" className="text-primary font-medium ml-2 underline">
                            Se våre hjelpesider
                        </a>
                    </p>
                </div>
            </div>
        </div>
	);
};

export default TextChecker;
